<template>
    <div class="card card-default" :class="loading ? 'whirl' : ''">
        <div class="card-header bg-gray-lighter text-bold">{{$t('common.view.settings.language.heading')}}</div>
        <div class="card-body">

            <div class="locale-changer">
                {{$t('common.view.settings.language.description')}}
                <b-select v-model="settings.language" @change="setLanguage()">
                    <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ langsFull[i] }}</option>
                </b-select>
            </div>

            <b-btn-toolbar class="justify-content-end mt-3">
                <b-btn variant="primary" @click="saveInDB()">{{$t('common.view.settings.language.save')}}</b-btn>
            </b-btn-toolbar>

        </div>
    </div>
</template>

<script>
    import store from "@/store/index";
    import { loadRouteLanguage } from "@/router";

    export default {
        components: {},
        data() {
            return {
                langs: ['en', 'de'],
                langsFull: [
                    this.$t('common.view.settings.language.select.english'),
                    this.$t('common.view.settings.language.select.german')
                ],
                loading: false,
                settings: {
                    language: this.$i18n.locale
                }
            }
        },
        methods: {
            setLanguage() {
                loadRouteLanguage(this.settings.language).then(() => {
                    this.$i18n.locale = this.settings.language;
                    this.$moment.locale(this.settings.language);
                });
            },
            saveInDB() {
                store.commit('updateLanguage', this.settings.language);
                this.loading = true;
                this.$api.update('user/settings', this.settings).then((response) => {
                    this.loading = false;
                    this.settings = response.data;
                    this.$swal.fire(this.$t('common.popup.saved.title'), '', 'success');
                });
            },
        },
    }
</script>